@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");

button {
  text-transform: capitalize;
}

.fade-loading {
  width: 30px;
  height: 30px;
  background-color: #8067dc;
  border-radius: 100rem;
  margin: 10px auto;
  position: relative;
}

.fade-loading:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: inherit;
  animation: fade 1s forwards infinite linear;
}

@keyframes fade {
  to {
    transform: scale(2);
    opacity: 0;
  }
}

body {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: black;
  height: 100vh;
  margin: 0;
  overflow-x: hidden;
  background-color: #ffffff;
  padding-left: 5px;
}

* {
  box-sizing: border-box;
}

#root {
  height: 100%;
}

.chat-session ul li {
  line-height: 1.5;
}

a {
  color: currentColor;
  text-transform: none;
  text-decoration: none;
}

.text-heading {
  font-weight: 700;
  font-size: 30px;
  line-height: 50px;
  color: black;
  font-family: "Inter", sans-serif !important;
}

.text-title {
  line-height: 22px;
  font-size: 18px;
  color: black;
  font-weight: 600;
  font-family: "Inter", sans-serif !important;
}

.text-content {
  font-weight: 400;
  font-size: 16px;
  list-style: 20px;
  color: black;
  font-family: "Inter", sans-serif !important;
}

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__input_computer {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  padding: 0 12px;
  background-color: #1976d2;
  height: 40px;
  position: relative;
}

.file-input__label_computer {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

.file-input_computer {
  display: flex;
}

.render-markdown {
  color: black;
  padding-left: 37px;
  font-size: 14px;
}

.render-markdown img {
  min-width: 25%;
  max-width: 100%;
  height: auto;
  min-height: 200px;
  margin: 0 auto;
  object-fit: contain;
}

.render-markdown p {
  line-height: 22px;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.render-markdown table {
  margin: 0 auto;
  border-collapse: collapse;
}

.render-markdown table,
.render-markdown table tbody tr td,
.render-markdown table thead tr th {
  border: 1px solid #cccccc;
  font-size: 14px;
}

.render-markdown table tbody tr td,
.render-markdown table thead tr th {
  padding: 8px;
}

.render-markdown ol,
.render-markdown ul {
  font-size: 14px;
}

.render-markdown li {
  font-size: 14px;
}

.resize-box {
  position: relative !important;
  transform: translate(0, 0) !important;
  box-sizing: border-box;
  height: calc(100vh - 114px) !important;
}

.disable-element {
  pointer-events: none;
  opacity: 0.5;
}

.hover-icon {
  cursor: pointer;
  transition: all 0.5s;
}

.hover-icon:hover path {
  fill: #1672c9;
  transition: all 0.5s;
}

/* .hover-icon:hover {
  color: #1672c9;
} */

.default-svg {
  color: #737373;
}

.tool-default-svg {
  color: #404040;
}

.activate-svg {
  color: #1672c9;
}

.hover-svg:hover {
  color: #1672c9;
}

.hover-btn:hover svg {
  color: #1672c9;
}

.tool-activate-svg {
  color: #125ca1;
}

.activate-icon path {
  fill: #1672c9;
}

.loading__dot {
  animation: dot ease-in-out 1s infinite;
  background-color: grey;
  display: inline-block;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  margin: 2px;
}

.loading__dot:nth-of-type(2) {
  animation-delay: 0.2s;
}

.loading__dot:nth-of-type(3) {
  animation-delay: 0.3s;
}

@keyframes dot {
  0% {
    background-color: grey;
    transform: scale(1);
  }
  50% {
    background-color: #1672c9;
    transform: scale(1.3);
  }
  100% {
    background-color: grey;
    transform: scale(1);
  }
}

select#grouped-native-select {
  height: 40px !important;
  padding: 0 !important;
  padding-left: 15px !important;
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.line-clamp-6 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.default-boxshadow {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.text-base {
  font-size: 14px;
}

.mb-2 {
  margin-bottom: 10px;
}

.hide-document-session {
  width: 0 !important;
  opacity: 0;
}

.select-project-team a {
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
}

/* ::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: gray;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  height: 120px;
  background-color: #fff;
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
} */

.bottom-shadow-header {
  box-shadow: 0 5px 5px -5px #333;
}

.top-shadow-header {
  box-shadow: 0 -5px 5px -5px #333;
}

.trackVertical {
  right: 2px;
  bottom: 2px;
  top: 2px;
  border-radius: 3px;
  transition: all 200ms ease !important;
}

.trackVertical:hover,
.trackVertical:active {
  width: 12px !important;
  border-radius: 8px !important;
}

/* .trackVertical > div {
  height: 100px !important;
} */

.rotate-180 {
  transform: rotate(180deg);
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-1000 {
  transition: all 400ms;
}

.main-box-shadow {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.move-6 {
  transform: translateY(6px);
}

/* suneditor */

.se-list-layer {
  top: 50px !important;
  height: 450px !important;
  overflow-y: auto !important;
}

.sun-editor .se-toolbar {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 1000 !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.share-with-facebook-date-picker input {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  border-radius: 4px !important;
  padding: 10px 5px !important;
  font-size: 14px !important;
  width: 100% !important;
}

.share-with-facebook-date-picker input:focus {
  border-color: #1672c9 !important;
  outline: none !important;
}

.react-datepicker-popper {
  z-index: 20 !important;
}

.card-plan-detail strong,
.card-plan-detail h6 {
  font-weight: 400;
}

/* google button */
.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: "Roboto", arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: "Roboto", arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}

/* custom search icon */
/* #2684FF */
.custom-search-with-icon {
  outline: none;
  box-shadow: none;
  border: 1px solid hsl(0, 0%, 80%);
  transition: all 100ms;
  border-radius: 4px;
  height: 38px;
  line-height: 38px;
  padding-left: 30px;
}

.custom-search-with-icon:focus {
  box-shadow: 0 0 0 1px #2684ff;
  outline: none;
  transition: all 100ms;
  border: 1px solid #2684ff;
}

input.custom-search-with-icon::placeholder {
  font-size: 13px;
}

.custom-date-picker-schedule-post input {
  outline: none;
  box-shadow: none;
  border: 1px solid hsl(0, 0%, 80%);
  transition: all 100ms;
  border-radius: 4px;
  height: 38px;
  line-height: 38px;
  padding-left: 5px;
}

.custom-date-picker-schedule-post input:focus {
  box-shadow: 0 0 0 1px #2684ff;
  outline: none;
  transition: all 100ms;
  border: 1px solid #2684ff;
}

.custom-color-icon g {
  fill: #737373 !important;
}

.custom-color-icon:hover g {
  fill: #1672c9 !important;
}

.relative {
  position: relative;
}

.flex-direction-responsive {
  flex-direction: row;
}

.custom-text-responsive {
  font-size: 16px !important;
}

.custom-padding-x-responsive {
  padding-left: 30px;
  padding-right: 30px;
}

.custom-padding-add-plus-x-responsive {
  padding-left: 30px;
  padding-right: 30px;
}

.custom-height-responsive {
  height: calc(100vh - 170px);
}

@media screen and (max-width: 768px) {
  .hide-on-mobile {
    display: none !important;
  }

  .flex-direction-responsive {
    flex-direction: column;
    align-items: flex-start !important;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .custom-text-responsive {
    font-size: 12px !important;
  }

  .MuiFormLabel-root {
    font-size: 12px !important;
  }

  .custom-padding-x-responsive {
    padding-left: 10px;
    padding-right: 10px;
  }

  .custom-height-responsive {
    height: calc(100vh - 230px);
  }

  .custom-padding-add-plus-x-responsive {
    padding-left: 0;
    padding-right: 0;
  }

  .custom-input-chat-mui .MuiInputBase-input {
    padding-left: 0px !important;
  }

  .custom-input-chat-mui .MuiFormLabel-root {
    padding-left: 0px !important;
  }
}
